
import { AccessLogin } from "@/interfaces/GlobalsInterface";
import { ApiResponse, axios } from "@/libraries/axios";
import { session, settings } from "@/libraries/storage";
import { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";
import { namespace } from "vuex-class";

interface BootstrapRecord {
    longName: string
    shortName: string
    themeDark: Partial<VuetifyThemeVariant> | null
    themeLight: Partial<VuetifyThemeVariant> | null
}

const AclStore = namespace('AclStore')
const VrStore = namespace('VrStore')

@Component
export default class BootstrapView extends Vue {
    @AclStore.State
    userData!: Readonly<AccessLogin> | null

    @VrStore.Mutation
    setDarkMode!: () => void
    @VrStore.Mutation
    setLongName!: (longName: string) => void
    @VrStore.Mutation
    setShortName!: (shortName: string) => void
    @VrStore.Mutation
    setTheme!: (theme: {
        darkTheme: Partial<VuetifyThemeVariant> | null
        lightTheme: Partial<VuetifyThemeVariant> | null
    }) => void

    async mounted(): Promise<void> {
        await this.fetchAndActivateTheme()

        if (this.$route.meta?.redirectTo) {
            await this.$router.push(this.$route.meta.redirectTo)
        } else {
            if (!settings.has('auth', 'user', 'token')) {
                await this.$router.push('/login')
            } else {
                await this.$router.push('/app')
            }
        }
    }

    fetchAndActivateTheme(): Promise<AxiosResponse<ApiResponse<BootstrapRecord>>> {
        const worker = axios.get<ApiResponse<BootstrapRecord>>('/v1/pwa/bootstrap')
        worker.then(({data, status}) => {
            if ((status === 200) && (data.code === 302)) {
                const {
                    longName,
                    shortName,
                    themeDark: darkTheme,
                    themeLight: lightTheme,
                } = data.pyld

                this.setTheme({darkTheme, lightTheme})
                this.setLongName(longName)
                this.setShortName(shortName)
            }

            session.set('system','ux', 'bootstrap', Date.now())
        })

        return worker
    }
}
